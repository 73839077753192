@use "colors-generic" as *;

// Notification
$color-error: $red;
$color-success: $green-dark;
$color-warning: $orange-xxdark;
$color-info: $blue-dark;

// Site wide
$color-light: $neutral-xxlight;
$color-dark: $neutral-xxdark;
$color-primary: $pink;
$color-red: $red;
$color-secondary: $blue-dark;
$color-recruiter: $purple;

// Backgrounds
/* stylelint-disable-next-line color-no-hex */
$color-bg-enterprise-dark: #090614;
$color-bg-list-item: $neutral-xxlight;
$color-bg-list-item-hover: $neutral-xlight;

// Hover
$color-hover-primary: $pink;
$color-hover-secondary: $blue;

// Bar
$bar-item-active: transparent;
$bar-item-active-after: currentColor;

// Borders
$border-color-cards: var(--color-border-cards);
$border-color-cards-dark: $neutral-light;
$border-color-xlight: $neutral-xxxlight;
$border-color-light: var(--color-border-light);
$border-color-xdark: $neutral-xxdark;
$border-color-neutral: $neutral;
$border-color-dark: $neutral-light;
$border-color-indicator: $neutral-xxxlight;
$border-color-nav-light: $neutral-light;
$border-color-nav: $neutral-light;

// Link
$link-color-light: $neutral-xxxlight;
$link-color-pink: $pink;
$link-color-mid: $neutral-light;
$link-color-dark: $neutral-dark;

// Input/Forms
$input-bg: var(--color-bg-input);
$input-bg-disabled: var(--color-bg-input-disabled);
$input-bg-disabled-dark: $neutral-light;
$input-border-disabled: $neutral-light;
$input-text-color: $color-dark;
$field-border-color: var(--color-border-light);
$field-focus-color: $blue;
$field-focus-color-light: $blue;
$field-highlight-color: $blue-dark;
$placeholder-text-color: $neutral;
$placeholder-text-color-dark-bg: $neutral-light;
$radio-card-shadow: var(--box-shadow-glow);

// Buttons
$color-default-button-bg: var(--color-button-bg-default);
$color-default-button-text: var(--color-button-text-default);
$color-default-button-border: var(--color-button-border-default);
$color-success-button: $color-success;
$color-secondary-button: $color-secondary;
$color-primary-button: $color-primary;
$color-danger-button: $red-dark;
$color-recruiter-button: $purple;
$color-preferred-button: $orange-preferred-freelancer;

$color-trans-dark-button: $neutral-dark;
$color-trans-secondary-button: $color-secondary;
$color-trans-primary-button: $color-primary;
$color-trans-recruiter-button: $color-recruiter;
$color-trans-light-button: $neutral-xxxlight;

$color-text-blue-button: $color-secondary;
$color-text-primary-button: $color-primary;
$color-text-dark-button: $neutral-dark;
$color-text-light-button: $neutral-xxlight;
$color-text-recruiter-button: $color-recruiter;
$color-text-red-button: $color-error;

$disabled-button-bg: var(--color-button-bg-disabled);
$disabled-button-border: var(--color-button-border-disabled);
$disabled-button-text: var(--color-button-text-disabled);

// Footer
$footer-background: $neutral-xxdark;
$footer-border: $neutral-xdark;

// Overlay
$overlay-dark: rgba(25, 32, 45, 0.7);
$overlay-mid: rgba(25, 32, 45, 0.6);
$overlay-light: rgba(25, 32, 45, 0.3);
$overlay-xlight: rgba($neutral-xxxlight, 0.15);

// Tooltips
$tooltip-background: $neutral-xdark;

// Navigation
$navigation-background-color: $neutral-xxdark;
$navigation-background-color-app-bar: $neutral-xdark;
$navigation-background-color-secondary: $neutral-xxdark;
$navigation-background-color-tertiary: transparent;
$navigation-text-color: $neutral-xxxlight;
$navigation-text-color-secondary: $neutral-xxxlight;
$navigation-itemBtn-hover: $neutral-xdark;
$secondary-nav-bg: $neutral-xxdark;
$child-navigation-text-color: $neutral-xxxlight;
$child-navigation-text-color-secondary: $neutral-xxlight;
$child-navigation-text-color-secondary-light-mode: $neutral-xxdark;

// View Header
$view-header-light-color: $neutral-xxxlight;
$view-header-dark-color: $navigation-background-color-secondary;

// Messaging
$message-logged-in-user-bubble-color: $blue-xdark;
$message-logged-in-user-text-color: $neutral-xxxlight;
$message-logged-in-user-bubble-color-sending: $neutral-light;
$message-logged-in-user-bubble-text-color-sending: $neutral-xxxlight;
$message-logged-in-user-bubble-color-failed: $neutral-light;
$message-logged-in-user-bubble-text-color-failed: $neutral-xxdark;
$message-other-user-bubble-bg: var(--color-bg-message-bubble-other-user);
$message-other-user-bubble-text-color: var(--color-foreground);
$messaging-widget-shadow: var(--box-shadow-glow);

// On Behalf Of
$on-behalf-project-info-card-bg: $blue-xxdark;

// Post Project
$postproject-header-bg: linear-gradient(55deg, $neutral-xxdark, $blue-xxdark);
$postproject-header-border-gradient: var(--color-pjp-header-border-gradient);
$postproject-sidebar-highlight: $green-dark;
$postproject-sidebar-step-border: $neutral-xxdark;
$postproject-bg-description-entropy: var(--color-pjp-bg-description-entropy);

// Profile Page
$color-bg-profile-page: var(--color-bg-profile-page);
$color-fade-profile-page-review-filter: var(--color-fade-profile-page-filter);

// Project View Page
$pvp-waiting-screen-checklist-item-hover: var(--color-bg-mid);

// Table
$table-row-highlight-color: $blue-xlight;
$table-row-highlight-color-dark: $neutral-dark;

// Misc
$overlay-bg: $neutral-xxdark;

$tab-background-light: $neutral-xdark;
$tab-background-dark: $neutral-xxdark;
$tab-item-selected-text-color: $green;

$project-status: $green;

$search-header-bg: $neutral-xxdark;

$tab-item-selected-column-color: currentColor;
$tab-item-selected-row-color: currentColor;

$welcome-background-color: none;

$welcome-carousel-background: none;
$carousel-dot-color: $blue;

$bg-give-get-banner: var(--color-bg-give-get-banner);

$ribbon-primary-background: $blue;
